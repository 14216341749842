@import "../../styles/variables";

.title {
  font-weight: 100;
  font-size: 58px;
  margin-bottom: 35px;
  @media screen and (max-width: $medium) {
    position: relative;
    left: 90px;
    top: 10px;
    font-size: 42px;
    margin-bottom: 55px;
  }
}
