/**
* @desc         setup variables.
* @requires     none
*/

// [color - variables]
// branding
// $primary-dark-color: #2c2c2c;
// $primary-light-color: #f8f1ea;
// $danger-light-color: #e16b93;
// $action-dark-color: $primary-dark-color;

// text
$text-color: #fff;
$text-tag: #686c68;
$text-comp: #39ada3;
$text-bd: #3d7ac6;
$text-bl: #4fb1ef;
$text-bvl: #b5ddf6;
$text-gd: #3d764c;
$text-gl: #39ada3;
$text-gvl: #aebe8a;
$text-y: #ccb971;
$text-br: #b87040;
$text-p: #f583b8;

// body
$body-background: #424953;
$main-background: #111119;
$aside-background: #171723;
$menu-background: #1e1e1e;
$work-item-background: #25252B;

// font family
$primary-font: "Consolas";
$font-family-title: $primary-font, sans-serif;
$font-family-default: $primary-font, sans-serif;

// border colors
$border-color: #606568;

// border radius
// $border-radius: 10px;

// shadows
// $box-shadow-light: 0px 5px 30px rgba(0, 0, 0, 0.116);
// $box-shadow-medium: 0px 25px 30px #0000000f;

// media query
$small: 480px;
$medium: 768px;
$large: 1024px;
