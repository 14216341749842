@import '../../styles//variables';
.imageList {
  section {
    display: flex;
    justify-content: center;
    @media screen and (max-width: $medium) {
      flex-direction: column;
      width: 75%;
      justify-content: center;
      margin: auto;
    }
  }
  img {
    height: 100%;
    margin: 10px;
    border-radius: 15px;
    cursor: pointer;
  }
  .topImages {
    height: 172px;
     @media screen and (max-width: $medium) {
      height: auto;
    }
  }
  .botImages {
    margin-top: 15px;
    height: 390px;
     @media screen and (max-width: $medium) {
      height: auto;
    }
  }
}
.feats{
  margin-top: 35px;
}