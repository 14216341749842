@import "../../styles/variables";
.itemWrapper {
  background: $work-item-background;
  padding: 12px;
  border-radius: 12px;
  margin: 0 20px 15px;
  cursor: pointer;
  display: flex;
  @media screen and (max-width: $medium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    * {
      margin-top: 10px;
    }
  }
  img {
    height: 116px;
    width: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  .imageWrapper {
    flex: 1;
  }
  .desc {
   
    padding-left: 7px;
    flex: 2;
    .title {
      text-transform: uppercase;
    }
    .tech {
      margin-top: 10px;
    }
    .info {
      margin-top: 10px;
      line-height: 1;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: $medium) {
        max-width: 300px;
      }
    }
  }
  .stack {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-self: flex-end;
    @media screen and (max-width: $medium) {
      align-self: center;
    }
    span {
      padding: 8px;
      padding-left: 10px;
      padding-right: 10px;
      border: 1px $text-p solid;
      border-radius: 8px;
      height: fit-content;
      font-size: 11px;
      margin: 5px;
    }
  }
}
