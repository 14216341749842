

.action {
  cursor: pointer;
  position: relative;
  left: 1.5px;
  width: fit-content;
}
span {
  line-height: 1.4;
}
