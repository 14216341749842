.code-line {
  margin-bottom: 15px;
}
.text-tag {
  color: $text-tag;
}
.text-comp {
  color: $text-comp;
}
.text-bd {
  color: $text-bd;
}
.text-bl{
  color: $text-bl;
}
.text-bvl {
  color: $text-bvl;
}
.text-gd {
  color: $text-gd;
}
.text-gl {
  color: $text-gl;
}
.text-gvl {
  color: $text-gvl;
}
.text-y {
  color: $text-y;
}
.text-br {
  color: $text-br;
}
.text-p {
  color: $text-p;
}
.text-w {
  color:$text-color;
}

.anchor {
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
  }
}
.formFooter {
  display: none;
}