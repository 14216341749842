@import "../../styles/variables";

.socialWrapper {
  // position: absolute;
  // width: 100%;
  // bottom: 10px;
  .divider {
    border-top: 1px $border-color solid;
    margin: 0 25px 0 25px;
  }

  .list {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    figure {
      margin: 0 10px 0 10px;
    }
    li {
      margin-bottom: 15px;
    }
  }

  p {
    text-align: center;
  }
  .mail {
    margin-top: -8px;
  }
}
