@import "../../styles//variables";

.socials {
  position: absolute;
  z-index: 3;
  width: 100%;
}
.toggleButton {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  z-index: 2;
}

.navWrapper {
  position: absolute;
  top: 100px;
  // left: 30px;
  z-index: 2;
  height: calc(100vh - 150px);
  overflow: hidden;
  overflow-y: auto;
  width: calc(100% - 40px);
  left: 30px;
  font-size: 15px;
  // @media screen and (max-width: $medium) {
  //   width: 100%;
  // }
}

.navBackground {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  background: $menu-background;
  z-index: 2;
}

.leftBoarder {
  border-left: 1px solid $border-color;
  padding-left: 15px;
  line-height: 1.5;
}
