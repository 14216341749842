@import "./variables";

/**
* @desc         adds default text styles
* @requires     variables.scss
*/
body {
  color: $text-color;
}

body,
input,
select,
textarea,
p,
label {
  font-family: $font-family-default;
}

// headings and paragraph
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-family: $font-family-title;
}

h1,
h2 {
  font-weight: 600;
}

h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 26px;
}

@font-face {
  font-family: 'consolas';
  src: local('consolas'), url(../assets//fonts/consolas.TTF) format('truetype');
}