@import "../../styles//variables";
.layout {
  background: $body-background;
   height: 100%;
  .container {
     height: 100%;
    max-width: 1040px;
    margin: auto;
    display: flex;
    overflow: hidden;
  }
  aside {
    background: $aside-background;
    // height: 100%;
    width: 28%;
    padding: 10px;
    position: relative;
    @media screen and (max-width: $medium) {
      width: 0;
      position: static;
      padding: 0;
    }
  }
  main {
    background: $main-background;
    // height: 100%;
    width: 72%;
    padding: 10px;
    @media screen and (max-width: $medium) {
      width: 100%;
    }
  }
}
