@import "../../styles/variables";
.download {
  background: $text-comp;
  padding: 15px 25px;
  width: fit-content;
  border-radius: 8px;
  margin-top: 15px;
  margin-left: 25px;
  cursor: pointer;
  a {
    color: white;
  }
}
